<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'kelurahan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'kelurahan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'kelurahan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-add-new="formOnAddNew"
        :form-clone-mode="formCloneMode"/>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'

export default {
  components: {},
  props: {
    pageName: {
      type: String,
      default: 'Kelurahan'
    },
    title: {
      type: String,
      default: 'Kelurahan'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 180
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'kecamatanDelBatch',
      restoreRowsRoute: 'kecamatanRestoreBatch'
    })
    return data
  },
  created() {
    const vm = this
    vm.gridCreated()

    vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
      const formRef = vm.$refs[vm.formName]
      if (formRef === undefined) {
        return false
      }

      if (formRef.formDataEdit !== null) {
        formRef.form.getEditor('id_kota').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', formRef.formDataEdit.id_provinsi]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })

        formRef.form.getEditor('id_kecamatan').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', formRef.formDataEdit.id_kota]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })
      }
    })
  },
  mounted() {
    const vm = this
    vm.gridMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = command.rowData.id
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/kelurahan/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const vm = this.$refs[this.formName]
      if (e.value === undefined || !vm.formItemsSetStatus) {
        return false
      }
      const { formDataEdit } = vm
      if (e.dataField === 'id_provinsi') {
        vm.form.getEditor('id_kota').option({
          value: formDataEdit !== null ? formDataEdit.id_kota : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kota.nama']
          }
        })
      } else if (e.dataField === 'id_kota') {
        vm.form.getEditor('id_kecamatan').option({
          value: formDataEdit !== null ? formDataEdit.id_kota : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kecamatan.nama']
          }
        })
      }
    },
    getProvinsi() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan.kota.provinsi] : null
    },
    getKota() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan.kota] : null
    },
    getKecamatan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan] : null
    }
  }
}
</script>

<style>

</style>
